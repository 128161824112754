<ng-container *ifScreenSize="screenSize.Desktop">
  <div class="banner" role="banner">
    <h1>{{ name$ | async }}</h1>
  </div>
</ng-container>

<ng-container *ifScreenSize="screenSize.Mobile">
  <div class="container mt-3">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>

<fgb-account-nav></fgb-account-nav>

<div class="container">
  <div class="row">
    <ng-container *ifScreenSize="screenSize.Desktop">
      <div class="col-12 col-lg-6">
        <h5 class="text-left mb-3 mt-lg-0 text-capitalize text-white">{{ 'account.member.card.title' | translate }}</h5>
        <fgb-member-card [showSeating]="true"></fgb-member-card>
      </div>
    </ng-container>
    <div class="col-12 col-lg-6">
      <h5 class="text-left my-lg-3 mt-lg-0 text-capitalize text-white">{{ 'account.details.title' | translate }}</h5>
      <fgb-account-details></fgb-account-details>
    </div>
  </div>
</div>
