import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  AuthService,
  BalanceQuery,
  Card,
  CardQuery,
  MemberCard,
  MemberCardQuery,
  MemberDetails,
  MemberQuery,
  config,
} from '@fgb/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'fgb-mobile-nav-menu',
  templateUrl: './mobile-nav-menu.component.html',
  styleUrls: ['./mobile-nav-menu.component.scss'],
})
export class MobileNavMenuComponent implements OnInit, OnDestroy {
  memberDetails$: Observable<MemberDetails | undefined>;
  memberCard$: Observable<MemberCard | undefined>;
  pointsBalance$: Observable<number | undefined>;
  selectedMember: string;
  cards$: Observable<Card[]>;
  currentUrl: string = '';
  constructor(
    private authService: AuthService,
    private router: Router,
    private memberQuery: MemberQuery,
    private memberCardQuery: MemberCardQuery,
    private cardQuery: CardQuery,
    private balanceQuery: BalanceQuery
  ) {}
  private routerSubscription: Subscription;
  public isCollapsed = true;

  ngOnInit() {
    this.currentUrl = window.location.pathname;
    this.initMemberAccounts();
    this.routerSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        tap((navigation: any) => {
          this.currentUrl = navigation.url;
          return (this.isCollapsed = true);
        })
      )
      .subscribe();
  }

  initMemberAccounts() {
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.memberCard$ = this.memberCardQuery.selectCurrentCard();
    this.cards$ = this.cardQuery.selectCards();
    this.selectedMember = this.memberQuery.getUserContext().memberId;
    this.pointsBalance$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  closeMobileNav() {
    window.location.pathname === this.currentUrl && (this.isCollapsed = !this.isCollapsed);
  }

  logout() {
    this.authService
      .logout()
      .toPromise()
      .then(() => this.router.navigate(['/login']));
  }
}
