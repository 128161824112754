<ng-container *ngIf="card$ | async as memberCard; else loadingRef">
  <h2 class="accessibility-only">Member Card</h2>
  <div class="barcode-card mb-3 mb-lg-0">
    <div class="d-flex flex-column justify-content-between h-100">
      <!--Name and ID-->
      <div class="d-flex justify-content-between">
        <div class="card-id text-start">
          <p class="mb-0 card-name" *ngIf="memberDetails$ | async as memberDetails">
            {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
          </p>
          <div class="d-flex font-size-12">
            <div>{{ memberCard.ProductCode }}</div>
            <div class="px-1">-</div>
            <div>{{ memberCard.ExternalRefNumber }}</div>
          </div>
        </div>
        <img alt="" class="club-logo" src="assets/images/logo.png" draggable="false" />
      </div>
      <!--Balances-->
      <div class="d-flex justify-content-between align-items-center">
        <div class="card-points text-start" *ngIf="pointsBalance$">
          <label class="font-size-14 mb-1">{{ pointsBalance$ | async | number : '1.0-0' }}</label>
          <div class="font-size-12">{{ 'member.card.points.balance' | translate }}</div>
        </div>
        <div class="balance-splitter"></div>
        <div class="card-id text-start">
          <label class="font-size-14 mb-1">{{ memberCard.RetailDiscountLevel }}%</label>
          <div class="font-size-12">{{ 'member.card.retail.discount' | translate }}</div>
        </div>
        <div class="balance-splitter"></div>
        <div class="card-discount-level text-start">
          <label class="font-size-14 mb-1">{{ memberCard.DiscountLevel }}%</label>
          <div class="font-size-12">{{ 'member.card.discount' | translate }}</div>
        </div>
      </div>
      <button
        type="button"
        class="btn show-card-modal w-100 bg-white p-0 d-flex justify-content-center align-items-center"
        data-bs-toggle="modal"
        data-bs-target="#barcodeModal"
      >
        <span class="icon material-icons"> qr_code_2 </span>
        <span class="ms-1 fw-bold">Show Card</span>
      </button>
    </div>
  </div>
</ng-container>

<!--Barcode Modal-->
<div class="modal fade" id="barcodeModal" tabindex="-1" aria-labelledby="barcodeModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <ng-container *ngIf="card$ | async as memberCard">
      <div class="modal-content bg-white border-0" *ngIf="barcode$ | async as barcode">
        <div class="modal-body p-0 flex-column align-items-center d-flex flex-wrap justify-content-around mt-4">
          <!--Name and ID-->
          <div class="card-id text-start">
            <div class="d-flex justify-content-center">
              <p class="mb-0 card-name text-primary text-center font-size-16" *ngIf="memberDetails$ | async as memberDetails">
                {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
              </p>
              <div class="icon material-icons close-icon text-grey" data-bs-dismiss="modal">close</div>
            </div>
            <div class="d-flex font-size-10">
              <div>{{ memberCard.ProductCode }}</div>
              <div class="px-1">-</div>
              <div>ID: {{ memberCard.ExternalRefNumber }}</div>
            </div>
          </div>
          <div class="qr px-2 bg-white">
            <qrcode [qrdata]="barcode.Barcode" [size]="256" [level]="'M'" [usesvg]="true"></qrcode>
          </div>
          <div class="w-100 bg-primary">
            <!--Balances-->
            <div class="d-flex justify-content-between align-items-center text-white balance-section">
              <div class="card-points text-start ps-4" *ngIf="pointsBalance$">
                <label class="font-size-14 mb-1">{{ pointsBalance$ | async | number : '1.0-0' }}</label>
                <div class="font-size-12">{{ 'member.card.points.balance' | translate }}</div>
              </div>
              <div class="balance-splitter mx-3"></div>
              <div class="card-id text-start">
                <label class="font-size-14 mb-1">{{ memberCard.RetailDiscountLevel }}%</label>
                <div class="font-size-12">{{ 'member.card.retail.discount' | translate }}</div>
              </div>
              <div class="balance-splitter mx-3"></div>
              <div class="card-account-no text-start pe-4">
                <label class="font-size-14 mb-1">{{ memberCard.DiscountLevel }}%</label>
                <div class="font-size-12">{{ 'member.card.discount' | translate }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
