<div class="d-block">
  <ul class="nav nav-secondary mixed-loyalty-nav">
    <a
      class="nav-item reward-nav-item"
      [routerLink]="['/games/quiz']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      attr.aria-label="{{ 'game.nav.quiz.screenreader' | translate : { fgbdefault: 'Move to Trivia page' } }}"
    >
      <div class="icon material-icons" aria-hidden="true">live_help</div>
      <label>{{ 'games.nav.trivia' | translate }}</label>
    </a>

    <a
      class="nav-item reward-nav-item"
      [routerLink]="['/games/polls']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      attr.aria-label="{{ 'game.nav.polls.screenreader' | translate : { fgbdefault: 'Move to Polls page' } }}"
    >
      <div class="icon material-icons" aria-hidden="true">assessment</div>
      <label>{{ 'games.nav.polls' | translate }}</label>
    </a>
  </ul>
</div>
