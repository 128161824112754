import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  PopulationGuard,
  // StepType,
  MemberResolver,
  LottoResolver,
  AuctionResolver,
  MemberCardResolver,
  BalanceResolver,
  PurchaseResolver,
  CardTokenResolver,
  // RegistrationStepResolver,
  LottoWalletResolver,
  AuctionBidsResolver,
  TransactionSuccessGuard,
  SeatingDetailResolver,
  CountryResolver,
  MemberLoyaltyStatusResolver,
  MaintenanceGuard,
  FGBPreLoadingStrategy,
  firstTimeUserGuard,
  SurveyResolver,
} from '@fgb/core';

// General
import { MasterPageComponent } from './pages/master/master-page/master-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { ContactUsPageComponent } from './pages/contact-us-page/contact-us-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
// Loyalty
import { RewardsLayoutComponent } from './pages/rewards-layout/rewards-layout.component';
import { RewardsPageComponent } from './pages/rewards-page/rewards-page.component';
import { AuctionsPageComponent } from './pages/auctions-page/auctions-page.component';
import { AuctionDetailPageComponent } from './pages/auction-detail-page/auction-detail-page.component';
import { MarketplacePageComponent } from './pages/marketplace-page/marketplace-page.component';
import { MarketplaceDetailPageComponent } from './pages/marketplace-detail-page/marketplace-detail-page.component';
import { MarketplacePurchaseSuccessPageComponent } from './pages/marketplace-page/marketplace-purchase-success-page/marketplace-purchase-success-page.component';
import { LottoDetailPageComponent } from './pages/lotto-detail-page/lotto-detail-page.component';
import { LottosSuccessPageComponent } from './pages/lottos-page/lottos-success-page/lottos-success-page.component';
import { EventsPageComponent } from './pages/events-page/events-page.component';
import { WalletPageComponent } from './pages/wallet-page/wallet-page.component';
import { AuctionWalletDetailsPageComponent } from './pages/auction-wallet-details-page/auction-wallet-details-page.component';
import { LottoWalletDetailsPageComponent } from './pages/lotto-wallet-details-page/lotto-wallet-details-page.component';
import { BadgesPageComponent } from './pages/badges-page/badges-page.component';

// Account
import { AccountDetailsPageComponent } from './pages/account-details-page/account-details-page.component';
import { ActivityPageComponent } from './pages/activity-page/activity-page.component';

// Unauthenticated
import { AuthCallbackComponent } from './modules/user/components/auth/auth-callback/auth-callback.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LoginAccountsPageComponent } from './pages/login-accounts-page/login-accounts-page.component';
import { SingleSignOnComponent } from './modules/user/components/single-sign-on/single-sign-on.component';
import { MaintenancePageComponent } from './pages/maintenance-page/maintenance-page.component';
// Registration
// import { RegistrationPaymentComponent } from './modules/registration/pages/steps/registration-payment/registration-payment.component';

// Club Pages
import { EarnPageComponent } from './pages/earn-page/earn-page.component';

// Partners Pages
import { LottosTermsPageComponent } from './pages/lottos-terms-page/lottos-terms-page.component';
import { MarketplaceTermsPageComponent } from './pages/marketplace-terms-page/marketplace-terms-page.component';
import { TmAuthCallbackComponent } from './shared/components/providers/tm/tm-auth-callback/tm-auth-callback.component';

import { SportsAllianceCallbackComponent } from './shared/components/providers/sports-alliance/sports-alliance-callback/sports-alliance-callback.component';
import { ProviderCallbackComponent } from './shared/components/providers/provider-callback/provider-callback.component';
import { KeyCloakCallbackComponent } from './shared/components/providers/key-cloak/key-cloak-callback/key-cloak-callback.component';
import { SeatGeekCallbackComponent } from './shared/components/providers/seatgeek/seatgeek-callback/seatgeek-callback.component';
import { OtherMediaCallbackComponent } from './shared/components/providers/other-media/other-media-callback/other-media-callback.component';

import { RegistrationLayoutPageComponent } from './modules/registration/pages/registration-layout-page/registration-layout-page.component';
import { WalletClaimedPageComponent } from './pages/wallet-claimed-page/wallet-claimed-page.component';
import { RegistrationPaymentModule, provideRegistrationConfig } from '@fgb/portal-component-library/src/lib/registration';
import { MarketplaceWalletPageComponent } from './pages/marketplace-wallet-page/marketplace-wallet-page.component';
import { ChallengesPageComponent } from './pages/challenges-page/challenges-page.component';
import { PollsPageComponent } from './pages/polls-page/polls-page.component';
import { QuizPageComponent } from './pages/quiz-page/quiz-page.component';
import { GamesLayoutComponent } from './pages/games-layout/games-layout.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, firstTimeUserGuard()],
    canActivateChild: [MaintenanceGuard],
    component: MasterPageComponent,
    resolve: {
      member: MemberResolver,
      memberCard: MemberCardResolver,
      balances: BalanceResolver,
    },
    children: [
      {
        path: '',
        canActivate: [PopulationGuard],
        data: {
          exclude: ['testPop'],
          popRedirect: 'test',
        },
        component: HomePageComponent,
      },

      {
        path: 'rewards',
        component: RewardsLayoutComponent,
        children: [
          {
            path: '',
            component: RewardsPageComponent,
          },
          {
            path: 'auctions',
            children: [
              {
                path: '',
                resolve: { auctions: AuctionResolver },
                component: AuctionsPageComponent,
              },
              {
                path: 'details/:id',
                resolve: {
                  balances: BalanceResolver,
                  auctions: AuctionResolver,
                },
                component: AuctionDetailPageComponent,
              },
            ],
          },
          {
            path: 'marketplace',
            children: [
              {
                path: '',
                component: MarketplacePageComponent,
              },
              {
                path: 'details/:id',
                children: [
                  {
                    path: '',
                    resolve: {
                      balances: BalanceResolver,
                      cardTokens: CardTokenResolver,
                      country: CountryResolver,
                    },
                    component: MarketplaceDetailPageComponent,
                  },
                  {
                    path: 'terms',
                    component: MarketplaceTermsPageComponent,
                  },
                ],
              },
              {
                path: 'purchase/success',
                canActivate: [TransactionSuccessGuard],
                component: MarketplacePurchaseSuccessPageComponent,
                resolve: {
                  balances: BalanceResolver,
                  purchase: PurchaseResolver,
                },
              },
            ],
          },
          {
            path: 'events',
            component: EventsPageComponent,
          },

          {
            path: 'raffles',
            children: [
              {
                path: '',
                redirectTo: '/rewards',
                pathMatch: 'full',
              },
              {
                path: 'details/:id',
                children: [
                  {
                    path: '',
                    resolve: { balances: BalanceResolver, lottos: LottoResolver },
                    component: LottoDetailPageComponent,
                  },
                  {
                    path: 'terms',
                    component: LottosTermsPageComponent,
                    resolve: { lottos: LottoResolver },
                  },
                ],
              },
              {
                path: 'purchase/success',
                canActivate: [TransactionSuccessGuard],
                component: LottosSuccessPageComponent,
              },
            ],
          },
          {
            path: 'wallet',
            children: [
              {
                path: '',
                component: WalletPageComponent,
              },
              {
                path: 'details/:id',
                component: MarketplaceWalletPageComponent,
                resolve: { purchases: PurchaseResolver },
              },
              {
                path: 'raffledetails/:id',
                component: LottoWalletDetailsPageComponent,
                resolve: { lottoWalletItems: LottoWalletResolver },
              },
            ],
          },
          {
            path: 'claimed',
            children: [
              {
                path: '',
                component: WalletClaimedPageComponent,
                resolve: {
                  purchases: PurchaseResolver,
                  lottoWalletItems: LottoWalletResolver,
                  auctionBids: AuctionBidsResolver,
                },
              },
            ],
          },
          {
            path: 'bids',
            children: [
              {
                path: 'details/:id',
                component: AuctionWalletDetailsPageComponent,
                resolve: { bids: AuctionBidsResolver },
              },
            ],
          },
        ],
      },
      {
        path: 'account',
        component: AccountDetailsPageComponent,
        resolve: {
          member: MemberResolver,
          balances: BalanceResolver,
          seating: SeatingDetailResolver,
          memberLoyaltyStatus: MemberLoyaltyStatusResolver,
        },
      },

      {
        path: 'games',
        resolve: {
          surveys: SurveyResolver,
        },
        component: GamesLayoutComponent,
        children: [
          { path: 'polls', component: PollsPageComponent },
          { path: 'quiz', component: QuizPageComponent },
        ],
      },

      { path: 'activity', component: ActivityPageComponent },
      { path: 'faq', component: FaqPageComponent },
      { path: 'terms', component: TermsPageComponent },
      { path: 'contact', component: ContactUsPageComponent },
      { path: 'badges', component: BadgesPageComponent },
      { path: 'challenges', component: ChallengesPageComponent },

      // Club Pages
      { path: 'earn', component: EarnPageComponent },
      { path: '404page', component: NotFoundPageComponent },
      {
        path: 'provider/:providerid',
        component: ProviderCallbackComponent,
      },
    ],
  },

  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginPageComponent, canActivate: [MaintenanceGuard] },
  { path: 'login/accounts', component: LoginAccountsPageComponent, canActivate: [MaintenanceGuard] },

  {
    path: 'register',
    component: RegistrationLayoutPageComponent,
    loadChildren: () => import('@fgb/portal-component-library/src/lib/registration').then((m) => m.RegistrationModule),
    providers: [provideRegistrationConfig()],
  },
  { path: 'callback', component: AuthCallbackComponent },
  { path: 'sso', component: SingleSignOnComponent },
  { path: 'login/singleSignOn/:providerId', component: SingleSignOnComponent },
  { path: 'login/singleSignOn/:providerId/:clubId', component: SingleSignOnComponent },
  { path: 'maintenance', component: MaintenancePageComponent },
  { path: 'tm-auth-callback', component: TmAuthCallbackComponent },
  { path: 'sa-callback', component: SportsAllianceCallbackComponent },
  { path: 'kc-callback', component: KeyCloakCallbackComponent },
  { path: 'om-callback', component: OtherMediaCallbackComponent },
  { path: 'sg-callback', component: SeatGeekCallbackComponent },
  { path: '**', redirectTo: '404page' },
];

@NgModule({
  imports: [
    RegistrationPaymentModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: FGBPreLoadingStrategy,
      // bindToComponentInputs: true ---> TODO: Uncomment this when Angular version is updated to v16.
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
