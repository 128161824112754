import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ShortLongDescriptionModule } from '@fgb/portal-component-library/src/lib/shared/short-long-description';
import { ErrorsModule } from '@fgb/portal-component-library/src/lib/shared/errors';
import { FilterByModule } from '@fgb/portal-component-library/src/lib/shared/filter-by';
import { OfferSearchBarModule } from '@fgb/portal-component-library/src/lib/club/offer-search-bar';
import { CountdownModule } from '@fgb/portal-component-library/src/lib/shared/countdown';
import { TimeSinceModule } from '@fgb/portal-component-library/src/lib/shared/time-since';
import { NotificationButtonComponent } from './components/notification/notification-button/notification-button.component';
import { NotificationModule } from '@fgb/portal-component-library/src/lib/club/notification';
import { DirectivesModule } from '@fgb/portal-component-library/src/lib/directives';
import { SurveyItemComponent } from './components/survey/survey-item/survey-item.component';
import { SurveyItemAnswerComponent } from './components/survey/survey-item-answer/survey-item-answer.component';
import { SurveyItemQuizComponent } from './components/survey/survey-item/survey-item-quiz/survey-item-quiz.component';
import { SurveyItemPollComponent } from './components/survey/survey-item/survey-item-poll/survey-item-poll.component';
import { SurveyCompletedPollComponent } from './components/survey/survey-item-answer/completed/survey-completed-poll/survey-completed-poll.component';
import { SurveyCompletedQuizComponent } from './components/survey/survey-item-answer/completed/survey-completed-quiz/survey-completed-quiz.component';
import { SurveyCompletedPollItemComponent } from './components/survey/survey-item-answer/completed/survey-completed-poll/survey-completed-poll-item/survey-completed-poll-item.component';
import { SurveyCompletedQuizTextComponent } from './components/survey/survey-item-answer/completed/survey-completed-quiz/survey-completed-quiz-text/survey-completed-quiz-text.component';
import { SurveyCompletedQuizImageAndTextComponent } from './components/survey/survey-item-answer/completed/survey-completed-quiz/survey-completed-quiz-image-and-text/survey-completed-quiz-image-and-text.component';
import { CountdownV2Component } from '@fgb/portal-component-library/src/lib/shared/countdown-v2';

@NgModule({
  declarations: [
    NotificationButtonComponent,
    SurveyItemComponent,
    SurveyItemAnswerComponent,
    SurveyItemQuizComponent,
    SurveyItemPollComponent,
    SurveyCompletedPollComponent,
    SurveyCompletedQuizComponent,
    SurveyCompletedPollItemComponent,
    SurveyCompletedQuizTextComponent,
    SurveyCompletedQuizImageAndTextComponent,
  ],
  imports: [
    NgbModule,
    SharedModule,
    CommonModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ShortLongDescriptionModule,
    OfferSearchBarModule,
    ErrorsModule,
    FilterByModule,
    CountdownModule,
    TimeSinceModule,
    NotificationModule,
    DirectivesModule,
    CountdownV2Component,
    ToastrModule.forRoot(),
    InlineSVGModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: function HttpLoaderFactory(http: HttpClient) {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
    RouterModule,
  ],
  exports: [
    NotificationButtonComponent,
    SurveyItemComponent,
    SurveyItemAnswerComponent,
    SurveyItemQuizComponent,
    SurveyItemPollComponent,
    SurveyCompletedPollComponent,
    SurveyCompletedQuizComponent,
    SurveyCompletedPollItemComponent,
    SurveyCompletedQuizTextComponent,
    SurveyCompletedQuizImageAndTextComponent,
  ],
})
export class ClubModule {}
