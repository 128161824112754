<ng-container *ngIf="memberCard$ | async as memberCard; else loadingRef">
  <h2 class="accessibility-only">Member Card</h2>
  <div class="member-card mb-3 mb-lg-0">
    <div class="d-flex flex-column justify-content-between h-100">
      <!--Name and ID-->
      <div class="d-flex justify-content-between">
        <div class="card-id text-start">
          <p class="mb-0 card-name" *ngIf="memberDetails$ | async as memberDetails">
            {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
          </p>
          <div class="d-flex font-size-12">
            <div>{{ memberCard.ProductCode }}</div>
            <div class="px-1">-</div>
            <div>{{ memberCard.ExternalRefNumber }}</div>
          </div>
        </div>
        <img alt="" class="club-logo" src="assets/images/logo.png" draggable="false" />
      </div>

      <!--Balances-->
      <div class="d-flex justify-content-start align-items-center">
        <div class="card-points text-start" *ngIf="pointsBalance$">
          <label class="font-size-14 mb-1">{{ pointsBalance$ | async | number : '1.0-0' }}</label>
          <div class="font-size-12">{{ 'member.card.points.balance' | translate }}</div>
        </div>
        <div class="balance-splitter mx-3"></div>
        <div class="card-id text-start">
          <label class="font-size-14 mb-1">{{ memberCard.RetailDiscountLevel }}%</label>
          <div class="font-size-12">{{ 'member.card.retail.discount' | translate }}</div>
        </div>
        <div class="balance-splitter mx-3"></div>
        <div class="card-account-no text-start">
          <label class="font-size-14 mb-1">{{ memberCard.DiscountLevel }}%</label>
          <div class="font-size-12">{{ 'member.card.discount' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
