<div class="full-page bg-black container-fluid w-100 p-0">
  <div class="overflow-hidden p-0">
    <div class="row m-0 wrap-page">
      <div class="col-lg-6 d-none d-lg-block login-banner"></div>
      <div class="user-login-panel col-lg-6 col-12 text-left">
        <div class="row h-100">
          <div class="col-12">
            <div class="bg-primary d-flex d-lg-none justify-content-center mb-3">
              <img src="assets/images/logo.png" class="logo" />
            </div>
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
