<div class="full-page w-100 d-lg-flex d-block px-0">
  <!--Left Side-->
  <div class="left-side-login">
    <img
      class="shared-login-banner d-lg-block d-none"
      draggable="false"
      src="/assets/images/login-registration/login-banner-v2.jpg"
    />
  </div>
  <div class="bg-primary d-flex w-100 d-lg-none m-auto mobile-header"></div>
  <!--Right Side-->
  <div class="right-side-login">
    <div class="right-side-container">
      <h3 class="mb-4 text-primary">{{ 'login.page.title' | translate }}</h3>
      <div class="panel-text font-size-14 mb-4">
        {{ 'login.page.description' | translate }}
      </div>
      <fgb-tm-user-login></fgb-tm-user-login>

      <ng-container *ngIf="errorCode$ | async as errorCode">
        <div class="login-account-error text-center mt-5 pb-5" [innerHTML]="errorCode | translate | markdownTranslate"></div>
      </ng-container>
    </div>
  </div>
</div>
