<ng-container *ifScreenSize="screenSize.Desktop">
  <div class="banner" role="banner">
    <h1>{{ 'home.page.title' | translate }}</h1>
  </div>
</ng-container>

<ng-container *ifScreenSize="screenSize.Mobile">
  <div class="container mt-3">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>

<!--Voucher-->
<div class="container">
  <fgb-vouchers></fgb-vouchers>
</div>

<!--Scorecard carousel-->
<div class="mt-lg-4">
  <fgbcl-scorecard-carousel [icon]="'chevron_right'" [showAllView]="true" [phaseThree]="true"></fgbcl-scorecard-carousel>
</div>

<div class="container">
  <!-- Featured Rewards -->
  <fgbcl-featured-rewards></fgbcl-featured-rewards>

  <!-- Announcements -->
  <fgbcl-announcements></fgbcl-announcements>
</div>
