<div class="banner" role="banner">
  <h1>{{ 'games.page.trivia.title' | translate }}</h1>
</div>

<fgb-game-nav></fgb-game-nav>

<div class="container">
  <ng-container *ngIf="quizzes$ | async as quizzes">
    <div *ngIf="!quizzes.length">
      <div class="w-100 text-center no-items">
        <div class="no-results-placeholder-description card">
          {{ 'trivia.page.placeholder' | translate }}
        </div>
      </div>
    </div>
    <div *ngFor="let quiz of quizzes">
      <fgb-survey-item [survey]="quiz"></fgb-survey-item>
    </div>
  </ng-container>
</div>
