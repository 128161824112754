import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { MemberQuery, FGBTranslationLoader, FGBMissingTranslationHandler, AuthService } from '@fgb/core';

// ?? PIPES
import { ContentImagePipe } from './pipes/content-image.pipe';
import { FortressCurrencyPipe } from './pipes/fortress-currency.pipe';
import { ContentFilePipe } from './pipes/content-file.pipe';
import { CreditCardNumberPipe } from './pipes/credit-card-number.pipe';
import { CardTypeImageUrlPipe } from './pipes/card-type-image-url.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { WithLoadingPipe } from './pipes/with-loading.pipe';
import { NumberInputModule } from '@fgb/portal-component-library/src/lib/shared/number-input';
import { PipesModule } from '@fgb/portal-component-library/src/lib/pipes';

// ?? DIRECTIVES
import { ImgDefaultDirective } from './directives/img-default.directive';
import { InPopulationDirective } from './directives/in-population.directive';
import { NotInPopulationDirective } from './directives/not-in-population.directive';
import { IsClientTypeDirective } from './directives/is-client-type.directive';
import { IsNotClientTypeDirective } from './directives/is-not-client-type.directive';
import { StickyDirective } from './directives/sticky.directive';
import { InProductCodeDirective } from './directives/in-product-code.directive';
import { NotInProductCodeDirective } from './directives/not-in-product-code.directive';
import { HasTagDirective } from './directives/has-tag.directive';
import { CtrlTemplateDirective } from './directives/control-template.directive';
import { NotHasTagDirective } from './directives/not-has-tag.directive';
import { InClubDirective } from './directives/in-club.directive';
import { NotInClubDirective } from './directives/not-in-club.directive';

// ?? COMPONENTS
import { TmAuthCallbackComponent } from './components/providers/tm/tm-auth-callback/tm-auth-callback.component';
import { FgbYoutubePlayerComponent } from './components/providers/youtube/fgb-youtube-player/fgb-youtube-player.component';
import { SportsAllianceCallbackComponent } from './components/providers/sports-alliance/sports-alliance-callback/sports-alliance-callback.component';
import { ProviderCallbackComponent } from './components/providers/provider-callback/provider-callback.component';
import { KeyCloakCallbackComponent } from './components/providers/key-cloak/key-cloak-callback/key-cloak-callback.component';
import { SeatGeekCallbackComponent } from './components/providers/seatgeek/seatgeek-callback/seatgeek-callback.component';
import { OtherMediaCallbackComponent } from './components/providers/other-media/other-media-callback/other-media-callback.component';

// ?? MODULES
import { LoadingModule } from '@fgb/portal-component-library/src/lib/shared/loading';
import { ErrorsModule } from '@fgb/portal-component-library/src/lib/shared/errors';
import { CountryAndRegionsModule } from '@fgb/portal-component-library/src/lib/shared/country-and-regions';
import { CountdownModule } from '@fgb/portal-component-library/src/lib/shared/countdown';
import { LoyaltyIconModule } from '@fgb/portal-component-library/src/lib/shared/loyalty-icon';
import { SortBarModule } from '@fgb/portal-component-library/src/lib/shared/sort-bar';
import { DirectivesModule } from '@fgb/portal-component-library/src/lib/directives';
import { ConfirmModalModule } from '@fgb/portal-component-library/src/lib/shared/confirm-modal';

const PIPES = [
  ContentImagePipe,
  ContentFilePipe,
  FortressCurrencyPipe,
  CreditCardNumberPipe,
  CardTypeImageUrlPipe,
  SafeHtmlPipe,
  WithLoadingPipe,
];

const DIRECTIVES = [
  ImgDefaultDirective,
  InPopulationDirective,
  NotInPopulationDirective,
  IsNotClientTypeDirective,
  IsClientTypeDirective,
  StickyDirective,
  InProductCodeDirective,
  NotInProductCodeDirective,
  HasTagDirective,
  NotHasTagDirective,
  InClubDirective,
  NotInClubDirective,
  CtrlTemplateDirective,
];

const IMPORT_COMPONENTS = [
  TmAuthCallbackComponent,
  FgbYoutubePlayerComponent,
  SportsAllianceCallbackComponent,
  KeyCloakCallbackComponent,
  OtherMediaCallbackComponent,
  ProviderCallbackComponent,
  SeatGeekCallbackComponent,
];

const EXPORT_COMPONENTS = [FgbYoutubePlayerComponent, CtrlTemplateDirective];

const IMPORT_MODULES = [
  ReactiveFormsModule,
  FormsModule,
  CommonModule,
  NgbModule,
  RouterModule,
  InlineSVGModule,
  GoogleMapsModule,
  ErrorsModule,
  NumberInputModule,
  SortBarModule,
  CountdownModule,
  LoyaltyIconModule,
  LoadingModule,
  DirectivesModule,
  PipesModule,
  CountryAndRegionsModule,
  ConfirmModalModule,
  TranslateModule.forChild({
    loader: {
      provide: TranslateLoader,
      useClass: FGBTranslationLoader,
      deps: [HttpClient, AuthService, MemberQuery],
    },
    missingTranslationHandler: {
      provide: MissingTranslationHandler,
      useClass: FGBMissingTranslationHandler,
    },
  }),
];

const EXPORT_MODULES = [
  LoadingModule,
  GoogleMapsModule,
  PipesModule,
  NumberInputModule,
  CountryAndRegionsModule,
  ConfirmModalModule,
  DirectivesModule,
];

@NgModule({
  declarations: [...IMPORT_COMPONENTS, ...DIRECTIVES, ...PIPES],
  imports: [...IMPORT_MODULES],
  exports: [...EXPORT_COMPONENTS, ...DIRECTIVES, ...PIPES, ...EXPORT_MODULES],
})
export class SharedModule {}
