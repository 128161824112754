import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UserModule } from '../user/user.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { AccountNavComponent } from './components/account-nav/account-nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { MixedLoyaltyNavComponent } from './components/mixed-loyalty-nav/mixed-loyalty-nav.component';
import { MobileNavMenuComponent } from './components/mobile-nav-menu/mobile-nav-menu.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ClubModule } from '../club/club.module';
import { FormsModule } from '@angular/forms';
import { LanguageSelectorModule } from '@fgb/portal-component-library/src/lib/shared/language-selector';
import { LoyaltyIconModule } from '@fgb/portal-component-library/src/lib/shared/loyalty-icon';
import { CartItemModule } from '@fgb/portal-component-library/src/lib/loyalty/cart-item';
import { AccessibilityModule } from '@fgb/portal-component-library/accessibility';
import { NotificationModule } from '@fgb/portal-component-library/src/lib/club/notification';
import { NavbarDirectivesModule } from '@fgb/portal-component-library/src/lib/directives';
import { GameNavComponent } from './components/game-nav/game-nav/game-nav.component';
@NgModule({
  declarations: [
    NavBarComponent,
    FooterComponent,
    MixedLoyaltyNavComponent,
    AccountNavComponent,
    MobileNavMenuComponent,
    GameNavComponent,
  ],
  exports: [
    NavBarComponent,
    FooterComponent,
    MixedLoyaltyNavComponent,
    AccountNavComponent,
    MobileNavMenuComponent,
    GameNavComponent,
  ],
  imports: [
    RouterModule,
    NgbModule,
    CommonModule,
    FormsModule,
    InlineSVGModule,
    SharedModule,
    UserModule,
    ClubModule,
    LanguageSelectorModule,
    LoyaltyIconModule,
    CartItemModule,
    AccessibilityModule,
    NotificationModule,
    NavbarDirectivesModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: function HttpLoaderFactory(http: HttpClient) {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
  ],
})
export class LayoutModule {}
