import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {
  Survey,
  SurveyType,
  AnswerType,
  SurveyService,
  BalanceService,
  SurveyAnswerDto,
  PointProcessType,
  config,
  AnswerDisplayType,
} from '@fgb/core';

@Component({
  selector: 'fgb-survey-item-answer',
  templateUrl: './survey-item-answer.component.html',
  styleUrls: ['./survey-item-answer.component.scss', '../survey.scss'],
})
export class SurveyItemAnswerComponent implements OnInit {
  @Input() survey: Survey;
  @Output() predictorAnswers = new EventEmitter<[]>();
  selectedAnswers: number[] = [];
  surveyType = SurveyType;
  answerType = AnswerType;
  answerDisplayType = AnswerDisplayType;
  imgDisplayType: 'Portrait' | 'Landscape' = 'Portrait';
  colDisplayType: string;
  margins: string;
  boxBorder: string;

  constructor(private surveyService: SurveyService, private balanceService: BalanceService) {}

  ngOnInit() {
    this.selectImageDisplay();
    if (this.survey.AnswerDisplayType === this.answerDisplayType.Text || this.survey.SurveyType === this.surveyType.Poll) {
      if (this.survey.SurveyType === this.surveyType.Poll && this.survey.AnswerDisplayType === this.answerDisplayType.Image) {
        this.colDisplayType = 'col-6 col-lg-5';
      } else {
        this.colDisplayType = 'col-12 col-lg-5';
      }
      this.margins = 'my-2 my-lg-3';
    } else if (this.survey.SurveyType === this.surveyType.Predictor) {
      if (
        this.survey.AnswerDisplayType == this.answerDisplayType.Image ||
        this.survey.AnswerDisplayType == this.answerDisplayType.ImageAndText
      ) {
        this.colDisplayType = 'col-6';
        this.margins = 'my-2 my-lg-3';
      } else {
        this.colDisplayType = 'col-12';
        this.margins = 'mt-3';
        this.boxBorder = 'options-box-border';
      }
    } else {
      this.colDisplayType = 'col-6 col-lg-3';
      this.margins = 'my-3';
    }
  }

  selectImageDisplay() {
    if (this.survey.SurveyType === this.surveyType.Poll) {
      this.imgDisplayType = 'Landscape';
    }
  }

  selectAnswer(answerId: number) {
    let index = this.selectedAnswers.indexOf(answerId);
    if (index > -1) {
      this.selectedAnswers.splice(index, 1);
    } else {
      // Only allow one to be selected when the answertype is single
      if (this.survey.AnswerType === AnswerType.Single) {
        this.selectedAnswers = [];
      }
      this.selectedAnswers.push(answerId);
    }

    // Only submit the answer when the user can only select one answer
    if (this.survey.SurveyType === this.surveyType.Predictor) {
      this.predictorAnswers.emit();
    } else if (this.survey.AnswerType === AnswerType.Single) {
      this.submitAnswer();
    }
  }

  async submitAnswer() {
    let surveyId: number = this.survey.SurveyId;
    let answerDto: SurveyAnswerDto[] = [];
    for (let answerId of this.selectedAnswers) {
      answerDto.push({ surveyId, answerId });
    }

    await this.surveyService.postSurveyAnswers(this.survey.SurveyId, answerDto);

    if (this.survey.SurveyType === SurveyType.Predictor && this.survey.PointProcessType === PointProcessType.OnAnswer) {
      this.creditAccountForSurvey();
    }
  }

  /** Credit the loyalty balance with the point value of the first answer */
  private creditAccountForSurvey() {
    let firstSelectedAnswer = this.survey.SurveyAnswers.find((sa) => sa.SurveyAnswerId === this.selectedAnswers[0]);
    if (firstSelectedAnswer === undefined) {
      return;
    }

    this.balanceService.addBalance(config.purseConfig.virtualLoyalty, firstSelectedAnswer.PointValue);
  }
}
