<div class="mobile-nav-menu d-flex position-fixed flex-column d-lg-none">
  <!-- NAV BAR -->
  <div class="mobile-nav-bar d-flex justify-content-between">
    <a class="nav-item fw-bold brand p-1" [routerLink]="['/']" routerLinkActive="router-link-active">
      <label id="homeLink" class="accessibility-only">Go To Home Page</label>
      <img alt="" class="home-logo" src="assets/images/logo.png" draggable="false" />
    </a>
    <a class="nav-item" [routerLink]="['/challenges']">
      <span class="icon material-icons material-mobile-nav" role="img" attr.aria-label="{{ 'nav.account' | translate }}"
        >flag</span
      >
    </a>
    <a class="nav-item" [routerLink]="['/rewards']">
      <span class="icon material-icons material-mobile-nav" role="img" attr.aria-label="{{ 'nav.rewards' | translate }}"
        >redeem</span
      >
    </a>
    <a class="nav-item" [routerLink]="['/rewards/wallet']">
      <span class="icon material-icons material-mobile-nav" role="img" attr.aria-label="{{ 'nav.account' | translate }}"
        >account_balance_wallet</span
      >
    </a>

    <button
      attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
      class="nav-item fw-bold navbar-toggler m-0 px-2"
      (click)="isCollapsed = !isCollapsed"
    >
      <span class="icon material-icons material-mobile-nav">menu</span>
    </button>
  </div>

  <!-- NAV SCREEN -->
  <div [ngbCollapse]="isCollapsed" class="mobile-nav-menu-dropdown">
    <div class="d-flex flex-column p-1 mobile-nav-menu-content">
      <!-- Close Button -->
      <button
        class="close-button position-absolute border-0"
        attr.aria-label="{{ 'modal.close' | translate }}"
        (click)="isCollapsed = !isCollapsed"
      >
        <i class="material-icons close-btn">close</i>
      </button>
      <!-- Logo -->
      <div class="mb-3">
        <img alt="" class="logo px-2" src="assets/images/logo.png" draggable="false" />
      </div>
      <!-- Member Details -->
      <ng-container *ngIf="memberCard$ | async as memberCard">
        <div class="container text-white">
          <div class="mb-0 text-capitalize member-name" *ngIf="memberDetails$ | async as memberDetails">
            {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
          </div>
          <div class="mb-3 mt-2 font-size-12">
            <span>{{ memberCard.ProductCode }} - </span>
            <span>{{ memberCard.ExternalRefNumber }}</span>
          </div>
          <hr class="my-1 text-white w-100" />
          <div class="d-flex justify-content-between align-items-center my-2">
            <div class="card-points text-start" *ngIf="pointsBalance$">
              <label class="font-size-14 mb-1">{{ pointsBalance$ | async | number : '1.0-0' }}</label>
              <div class="font-size-12">{{ 'member.card.points.balance' | translate }}</div>
            </div>
            <div class="balance-splitter"></div>
            <div class="card-id text-start">
              <label class="font-size-14 mb-1">{{ memberCard.RetailDiscountLevel }}%</label>
              <div class="font-size-12">{{ 'member.card.retail.discount' | translate }}</div>
            </div>
            <div class="balance-splitter"></div>
            <div class="card-discount-level text-start">
              <label class="font-size-14 mb-1">{{ memberCard.DiscountLevel }}%</label>
              <div class="font-size-12">{{ 'member.card.discount' | translate }}</div>
            </div>
          </div>
          <hr class="my-1 text-white w-100" />
        </div>
      </ng-container>

      <!-- Nav Buttons -->
      <div class="mobile-nav-button-container">
        <a class="nav-item" [routerLink]="['/']" (click)="closeMobileNav()">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">home</span>
            <div class="nav-text fw-bold">{{ 'nav.home' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>
        <a class="nav-item" [routerLink]="['/account']" (click)="closeMobileNav()">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">person</span>
            <div class="nav-text fw-bold">{{ 'nav.account' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>
        <a class="nav-item" [routerLink]="['/rewards/wallet']" (click)="closeMobileNav()">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">account_balance_wallet</span>
            <div class="nav-text fw-bold">{{ 'nav.wallet' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>
        <a class="nav-item" [routerLink]="['/rewards']" (click)="closeMobileNav()">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">redeem</span>
            <div class="nav-text fw-bold">{{ 'nav.rewards' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item" [routerLink]="['/challenges']" (click)="closeMobileNav()">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">flag</span>
            <div class="nav-text fw-bold">{{ 'nav.challenges' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item" [routerLink]="['/badges']" (click)="closeMobileNav()">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">local_police</span>
            <div class="nav-text fw-bold">{{ 'nav.badges' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item" [routerLink]="['/games/quiz']" (click)="closeMobileNav()">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">extension</span>
            <div class="nav-text fw-bold">{{ 'nav.games' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.earn'">
          <a class="nav-item" [routerLink]="['/earn']" (click)="closeMobileNav()">
            <div class="nav-item-title-div">
              <span class="icon material-icons me-2" aria-hidden="true">emoji_events</span>
              <div class="nav-text fw-bold">{{ 'nav.earn' | translate }}</div>
            </div>
            <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
          </a>
        </ng-container>

        <a class="nav-item" [routerLink]="['/contact']" (click)="closeMobileNav()">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">email</span>
            <div class="nav-text fw-bold">{{ 'nav.contact.us' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item" [routerLink]="['/terms']" (click)="closeMobileNav()">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">article</span>
            <div class="nav-text fw-bold">{{ 'nav.terms' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item" [routerLink]="['/faq']" (click)="closeMobileNav()">
          <div class="nav-item-title-div">
            <span class="icon material-icons me-2" aria-hidden="true">help</span>
            <div class="nav-text fw-bold">{{ 'nav.faq' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item text-white logout-link-div" (click)="logout()">
          <span class="icon material-icons me-2" aria-hidden="true">power_settings_new</span>
          <div class="nav-text fw-bold">{{ 'nav.log.out' | translate }}</div>
        </a>
      </div>
    </div>
  </div>
</div>
