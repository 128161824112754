<div class="banner" role="banner">
  <h1>{{ 'challenges.page.title' | translate }}</h1>
</div>

<nav class="nav nav-secondary mixed-loyalty-nav my-0">
  <a
    attr.aria-label="{{ 'nav.challenges.screenreader' | translate }}"
    class="nav-item"
    [routerLink]="['/challenges']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <div class="icon material-icons" aria-hidden="true">flag</div>

    <label class="">{{ 'nav.challenges' | translate }}</label>
  </a>

  <a
    class="nav-item"
    [routerLink]="['/badges']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'nav.badges.screenreader' | translate : { fgbdefault: 'Move to badges page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">local_police</div>
    <label>{{ 'nav.badges' | translate }}</label>
  </a>
</nav>

<!--Scorecards-->
<div class="mt-4">
  <fgbcl-scorecard-list-view [icon]="'chevron_right'" [phaseThree]="true" [showPlaceholder]="true"></fgbcl-scorecard-list-view>
</div>
