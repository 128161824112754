<div class="survey-container rounded-4">
  <div class="survey-header text-center">
    <div class="no-image text-white bg-poll">
      <h3 class="no-image-title">{{ 'survey.item.poll.title' | translate }}</h3>
    </div>

    <div class="survey-info">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <div class="icon-polls material-icons inline-block my-0 text-white" aria-hidden="true">assessment</div>
        </div>
        <div class="px-3 d-none d-lg-block survey-question font-size-14">{{ survey.SurveyQuestion }}</div>

        <div class="text-end text-white" *ngIf="!survey.HasBeenAnsweredByUser">
          <span class="material-icons align-middle pe-1">schedule</span>
          <fgbcl-countdown-v2
            [endDateTime]="survey.EndDate"
            [requestDateTime]="survey.RequestTime"
            [isUTC]="true"
            class="font-size-14 fw-bold text-white"
          ></fgbcl-countdown-v2>
        </div>
        <div *ngIf="pointsWon > 0" class="fw-bold text-white font-size-14">+{{ pointsWon }} Pts</div>
      </div>
      <div class="d-block d-lg-none mt-3 font-size-14 survey-question xs text-white text-start ps-1">
        {{ survey.SurveyQuestion }}
      </div>
    </div>
  </div>
  <ng-container *ngIf="survey.HasBeenAnsweredByUser; else answer">
    <fgb-survey-item-answer-completed-poll [survey]="survey" [pointsWon]="pointsWon"></fgb-survey-item-answer-completed-poll>
  </ng-container>
  <ng-template #answer>
    <fgb-survey-item-answer [survey]="survey"></fgb-survey-item-answer>
  </ng-template>
</div>
