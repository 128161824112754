import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-games-layout',
  templateUrl: './games-layout.component.html',
  styleUrls: ['./games-layout.component.scss'],
})
export class GamesLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
