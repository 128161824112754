<!-- <fgb-navigation-indicator></fgb-navigation-indicator> -->

<ng-container *ngIf="!navigationSettings?.mobile.hidden">
  <fgb-mobile-nav-menu *ifScreenSize="screenType.Mobile"></fgb-mobile-nav-menu>
</ng-container>

<fgb-nav-bar *ifScreenSize="screenType.Desktop"></fgb-nav-bar>

<div id="main" role="main">
  <router-outlet></router-outlet>
</div>

<fgb-footer></fgb-footer>
