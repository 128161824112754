<div class="survey-answer row my-3">
  <div class="col-md-6 order-md-0 order-12">
    <div
      class="survey-answer-container"
      [ngClass]="hasSelectedCorrect ? 'justify-content-lg-end justify-content-center me-0 me-lg-4' : 'justify-content-around'"
    >
      <div
        class="survey-answer-item m-2"
        [ngClass]="hasSelectedCorrect ? 'correct' : 'incorrect'"
        *ngFor="let answer of selectedAnswers"
      >
        <div class="image-container rounded-top-4">
          <img src="{{ answer.ImageURL | contentImage : '7' }}" alt="{{ answer.ImageURL }}" />
        </div>
        <div class="answer-text text-center text-white">
          <span>{{ answer.Title }}</span>
        </div>
      </div>
      <div class="survey-answer-item correct m-2" *ngIf="!hasSelectedCorrect && correctAnswer">
        <div class="image-container rounded-top-4">
          <img src="{{ correctAnswer.ImageURL | contentImage : '7' }}" alt="{{ correctAnswer.ImageURL }}" />
        </div>
        <div class="answer-text text-center text-center text-white">
          <span>{{ correctAnswer.Title }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="survey-answer-text-container col-md-6 order-md-12 order-0">
    <ng-container *ngIf="hasSelectedCorrect; else incorrect">
      <div class="survey-message text-success">
        <div>
          <ng-container *ngIf="pointsWon; else noPoints">
            <div class="text-center fw-bold answer-img-text">{{ 'trivia.correct.answer' | translate }}</div>
            <h3 class="text-center text-capitalize points-won fw-bold answer-img-text-desc">
              +{{ pointsWon }} {{ 'trivia.points' | translate }}
            </h3>
          </ng-container>
          <ng-template #noPoints>
            <div class="fw-bold font-size-14">{{ 'trivia.correct.answer' | translate }}</div>
          </ng-template>
        </div>
      </div>
    </ng-container>
    <ng-template #incorrect>
      <div class="survey-message text-white">
        <div>
          <div class="text-center fw-bold answer-img-text">{{ 'trivia.wrong.answer' | translate }}</div>
          <h3 class="text-center text-capitalize points-won fw-bold answer-img-text-desc">
            {{ 'trivia.wrong.answer.desc' | translate }}
          </h3>
        </div>
      </div>
    </ng-template>
  </div>
</div>
