<!-- Banner -->
<div class="d-none d-lg-block">
  <div class="banner" role="banner">
    <h1>{{ 'auction.page.title' | translate }}</h1>
  </div>
  <!-- Nav Bar -->
  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</div>
<!-- Points Summary -->
<div class="container">
  <fgb-points-summary-bar [backButtonLink]="'/rewards/auctions'" [backButtonTitle]="'Auctions'"></fgb-points-summary-bar>
</div>

<!-- Auction Details -->
<fgbcl-auction-details [auctionId]="id"></fgbcl-auction-details>

<!-- Back Button -->
<div class="container">
  <div class="text-primary pt-3 cursor-pointer d-none d-lg-block" [routerLink]="'/rewards/auctions'">
    <span class="material-icons align-middle fw-bold pe-2 font-size-10">navigate_before</span>
    <span class="font-size-14 fw-bold">Back to Auctions</span>
  </div>
</div>
