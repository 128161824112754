// External Dependencies
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { JwtModule } from '@auth0/angular-jwt';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { QRCodeModule } from 'angularx-qrcode';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToastrModule } from 'ngx-toastr';
import { InlineSVGModule } from 'ng-inline-svg-2';
// Routing & Configuration
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
// Feature Modules
import { SharedModule } from './shared/shared.module';
import { ClubModule } from './modules/club/club.module';
import { UserModule } from './modules/user/user.module';
import { LoyaltyModule } from './modules/loyalty/loyalty.module';
import { VouchersModule } from './modules/vouchers/vouchers.module';
import { PurchaseModule } from './modules/purchase/purchase.module';

// Utilities
import { MomentDateFormatter } from './shared/utilities/moment-date-formatter';
// @fgb/core
import {
  AuthGuard,
  PopulationGuard,
  ProductCodeGuard,
  MaintenanceGuard,
  jwtTokenGetter,
  AuthService,
  TMOauthService,
  TMAuthService,
  tmAuthConfig,
  tmOAuthConfig,
  FGBMissingTranslationHandler,
  NotificationLoyaltyType,
  provideAppConfig,
  provideTMAuth,
} from '@fgb/core';

// Component Library
import { CardTokenListModule } from '@fgb/portal-component-library/src/lib/ecash/card-token-list';
import { EcashDescriptionModule } from '@fgb/portal-component-library/src/lib/ecash/ecash-description';
import { LoadingModule } from '@fgb/portal-component-library/src/lib/shared/loading';
import { ErrorsModule } from '@fgb/portal-component-library/src/lib/shared/errors';
import { EarnPointsModule } from '@fgb/portal-component-library/src/lib/club/earn-points';
import { FaqSectionModule } from '@fgb/portal-component-library/src/lib/club/faq-section';
import { TermsAndConditionsModule } from '@fgb/portal-component-library/src/lib/club/terms-and-conditions';
import { CountryAndRegionsModule } from '@fgb/portal-component-library/src/lib/shared/country-and-regions';
import { DirectivesModule } from '@fgb/portal-component-library/src/lib/directives';
import { AuctionDetailsModule } from '@fgb/portal-component-library/src/lib/loyalty/auctions/auction-details';
import { AuctionPanelModule } from '@fgb/portal-component-library/src/lib/loyalty/auctions/auction-panel';
import { AuctionListModule } from '@fgb/portal-component-library/src/lib/loyalty/auctions/auction-list';
import { LottoDetailsModule } from '@fgb/portal-component-library/src/lib/loyalty/lottos/lotto-details';
import { LottosListItemModule } from '@fgb/portal-component-library/src/lib/loyalty/lottos/lottos-list-item';
import { LottosListModule } from '@fgb/portal-component-library/src/lib/loyalty/lottos/lottos-list';
import { MarketplaceDetailsModule } from '@fgb/portal-component-library/src/lib/loyalty/marketplace/marketplace-details';
import { MarketplaceListItemModule } from '@fgb/portal-component-library/src/lib/loyalty/marketplace/marketplace-list-item';
import { MarketplaceListModule } from '@fgb/portal-component-library/src/lib/loyalty/marketplace/marketplace-list';
import { BadgeListItemModule } from '@fgb/portal-component-library/src/lib/loyalty/badges/badge-list-item';
import { MixedLoyaltyListModule } from '@fgb/portal-component-library/src/lib/loyalty/mixed-loyalty';
import { BadgeOverviewListModule } from '@fgb/portal-component-library/src/lib/loyalty/badges/badge-overview-list';
import { BadgeFeaturedListModule } from '@fgb/portal-component-library/src/lib/loyalty/badges/badge-featured-list';
import { FeaturedRewardsModule } from '@fgb/portal-component-library/src/lib/loyalty/featured-rewards';
import { AddressBookModule } from '@fgb/portal-component-library/src/lib/user/address-book';
import { ShortLongDescriptionModule } from '@fgb/portal-component-library/src/lib/shared/short-long-description';
import { ContactUsModule } from '@fgb/portal-component-library/src/lib/club/contact-us';

// General Pages
import { AppComponent } from './app.component';
import { MasterPageComponent } from './pages/master/master-page/master-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LoginAccountsPageComponent } from './pages/login-accounts-page/login-accounts-page.component';
import { MaintenancePageComponent } from './pages/maintenance-page/maintenance-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { ContactUsPageComponent } from './pages/contact-us-page/contact-us-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';

// Loyalty Pages
import { RewardsLayoutComponent } from './pages/rewards-layout/rewards-layout.component';
import { RewardsPageComponent } from './pages/rewards-page/rewards-page.component';
import { AuctionsPageComponent } from './pages/auctions-page/auctions-page.component';
import { LottosPageComponent } from './pages/lottos-page/lottos-page.component';
import { MarketplacePageComponent } from './pages/marketplace-page/marketplace-page.component';
import { EventsPageComponent } from './pages/events-page/events-page.component';
import { WalletPageComponent } from './pages/wallet-page/wallet-page.component';
import { AuctionDetailPageComponent } from './pages/auction-detail-page/auction-detail-page.component';
import { LottoDetailPageComponent } from './pages/lotto-detail-page/lotto-detail-page.component';
import { MarketplaceDetailPageComponent } from './pages/marketplace-detail-page/marketplace-detail-page.component';
import { LottosSuccessPageComponent } from './pages/lottos-page/lottos-success-page/lottos-success-page.component';
import { MarketplacePurchaseSuccessPageComponent } from './pages/marketplace-page/marketplace-purchase-success-page/marketplace-purchase-success-page.component';
import { LottoWalletDetailsPageComponent } from './pages/lotto-wallet-details-page/lotto-wallet-details-page.component';

// Account Pages
import { AccountDetailsPageComponent } from './pages/account-details-page/account-details-page.component';
import { ActivityPageComponent } from './pages/activity-page/activity-page.component';

// Club Pages
import { EarnPageComponent } from './pages/earn-page/earn-page.component';
import { LayoutModule } from './modules/layouts/layout.module';

import { LottosTermsPageComponent } from './pages/lottos-terms-page/lottos-terms-page.component';
import { MarketplaceTermsPageComponent } from './pages/marketplace-terms-page/marketplace-terms-page.component';
import { BadgesPageComponent } from './pages/badges-page/badges-page.component';
import { CardLinkModule } from '@fgb/portal-component-library/src/lib/cardlinking';
import { SharedSearchBarModule } from '@fgb/portal-component-library/src/lib/shared/shared-search-bar';
import { AnnouncementsModule } from '@fgb/portal-component-library/src/lib/club/announcements';
import { CountdownModule } from '@fgb/portal-component-library/src/lib/shared/countdown';
import { OfferSearchBarModule } from '@fgb/portal-component-library/src/lib/club/offer-search-bar';
import { CartItemModule } from '@fgb/portal-component-library/src/lib/loyalty/cart-item';
import { ClubSelectorModule } from '@fgb/portal-component-library/src/lib/user/club-selector';
import { EditQuestionsModule } from '@fgb/portal-component-library/src/lib/user/edit-questions';
import { RegistrationLayoutPageComponent } from './modules/registration/pages/registration-layout-page/registration-layout-page.component';
import { WalletClaimedPageComponent } from './pages/wallet-claimed-page/wallet-claimed-page.component';
import { NotificationModule } from '@fgb/portal-component-library/src/lib/club/notification';
import {
  ALERT_NOTIFICATION_COMPONENTS,
  AlertNotificationComponentMap,
  mergeAlertNotificationComponentMaps,
} from '@fgb/portal-component-library/src/lib/club/notification';
import { ServiceWorkerModule } from '@angular/service-worker';
import { WalletModule } from '@fgb/portal-component-library/src/lib/loyalty/wallet';
import { MarketplaceWalletPageComponent } from './pages/marketplace-wallet-page/marketplace-wallet-page.component';
import { ScorecardDescriptionModalComponent, ScorecardModule } from '@fgb/portal-component-library/src/lib/loyalty/scorecards';
import { AuctionWalletDetailsPageComponent } from './pages/auction-wallet-details-page/auction-wallet-details-page.component';
import { ChallengesPageComponent } from './pages/challenges-page/challenges-page.component';
import { FilterModalComponent } from './pages/rewards-page/filter-modal/filter-modal/filter-modal.component';
import { GamesLayoutComponent } from './pages/games-layout/games-layout.component';
import { QuizPageComponent } from './pages/quiz-page/quiz-page.component';
import { PollsPageComponent } from './pages/polls-page/polls-page.component';

const tmSSOAuthConfig: tmOAuthConfig = {
  clientId: '28ee107e7abd.fortress.sky-sky.us',
  club: 'sky',
  clientSecret: '60e58412170156f2cb6b269e8c6709877e92dc6adf9b0106a9303cfb061c9f22',
  tokenUri: 'https://auth.ticketmaster.com/api/oauth/token',
  loginUri: 'https://am.ticketmaster.com/sky/sso',
  authoriseUri: 'https://auth.ticketmaster.com/as/authorization.oauth2',
};

const tmLoginAuthConfig: tmAuthConfig = {
  clientId: '28ee107e7abd.fortress.sky-sky.us',
  authUri: 'https://auth.ticketmaster.com/as/authorization.oauth2',
  loginUri: 'https://am.ticketmaster.com/sky/sso',
  visualPresents: 'sky',
};

const alertNotificationComponents: Partial<AlertNotificationComponentMap> = {
  [NotificationLoyaltyType.ScorecardComplete]: ScorecardDescriptionModalComponent,
};

@NgModule({
  declarations: [
    // General Pages
    AppComponent,
    MasterPageComponent,
    HomePageComponent,
    LoginPageComponent,
    LoginAccountsPageComponent,
    MaintenancePageComponent,
    TermsPageComponent,
    FaqPageComponent,
    ContactUsPageComponent,
    NotFoundPageComponent,
    // Loyalty Pages
    RewardsLayoutComponent,
    RewardsPageComponent,
    AuctionsPageComponent,
    LottosPageComponent,
    MarketplacePageComponent,
    EventsPageComponent,
    WalletPageComponent,
    AuctionDetailPageComponent,
    LottoDetailPageComponent,
    MarketplaceDetailPageComponent,
    LottosSuccessPageComponent,
    MarketplacePurchaseSuccessPageComponent,
    LottoWalletDetailsPageComponent,
    AuctionWalletDetailsPageComponent,
    // Account Pages
    AccountDetailsPageComponent,
    ActivityPageComponent,
    // Club Pages
    EarnPageComponent,
    LottosTermsPageComponent,
    MarketplaceTermsPageComponent,
    BadgesPageComponent,
    // Registration
    RegistrationLayoutPageComponent,
    WalletClaimedPageComponent,
    MarketplaceWalletPageComponent,
    ChallengesPageComponent,
    FilterModalComponent,
    GamesLayoutComponent,
    QuizPageComponent,
    PollsPageComponent,
  ],
  imports: [
    // External Modules
    RouterModule,
    CarouselModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    InlineSVGModule.forRoot(),
    QRCodeModule,
    BrowserAnimationsModule,
    EarnPointsModule,
    FaqSectionModule,
    TermsAndConditionsModule,
    AuctionDetailsModule,
    AuctionPanelModule,
    AuctionListModule,
    LottoDetailsModule,
    LottosListModule,
    LottosListItemModule,
    ScorecardModule,
    BadgeListItemModule,
    BadgeOverviewListModule,
    BadgeFeaturedListModule,
    MarketplaceDetailsModule,
    ShortLongDescriptionModule,
    MarketplaceListModule,
    MarketplaceListItemModule,
    MixedLoyaltyListModule,
    LoadingModule,
    CountryAndRegionsModule,
    EcashDescriptionModule,
    CardTokenListModule,
    CountdownModule,
    ErrorsModule,
    EditQuestionsModule,
    FeaturedRewardsModule,
    AddressBookModule,
    WalletModule,
    NotificationModule,
    ContactUsModule,
    ToastrModule.forRoot({
      maxOpened: 5,
    }),
    JwtModule.forRoot({
      config: {
        allowedDomains: ['http://localhost/portal2/api'],
        tokenGetter: jwtTokenGetter,
      },
    }),
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: FGBMissingTranslationHandler,
      },
    }),
    // Feature Modules
    LayoutModule,
    PurchaseModule,
    ClubModule,
    PurchaseModule,
    VouchersModule,
    UserModule,
    SharedModule,
    LoyaltyModule,
    DirectivesModule,
    CardLinkModule,
    AnnouncementsModule,
    OfferSearchBarModule,
    SharedSearchBarModule,
    CartItemModule,
    ClubSelectorModule,
    ServiceWorkerModule.register('fgb-service-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    {
      provide: ALERT_NOTIFICATION_COMPONENTS,
      useFactory: () => {
        return mergeAlertNotificationComponentMaps(alertNotificationComponents);
      },
    },
    provideAppConfig({
      dateOptions: {
        LongDateFormat: 'MMM D, yyyy h:mma',
      },
    }),
    provideTMAuth({
      ssoAuth: tmSSOAuthConfig,
      loginAuth: tmLoginAuthConfig,
    }),
    TMOauthService,
    TMAuthService,
    AuthGuard,
    AuthService,
    PopulationGuard,
    ProductCodeGuard,
    MaintenanceGuard,
    DatePipe,
    CurrencyPipe,
    CookieService,
    FilterModalComponent,
    { provide: NgbDateParserFormatter, useClass: MomentDateFormatter },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
