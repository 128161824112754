import { Component, OnInit } from '@angular/core';
import { TransactionType } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';

@Component({
  selector: 'fgb-activity-page',
  templateUrl: './activity-page.component.html',
  styleUrls: ['./activity-page.component.scss'],
})
export class ActivityPageComponent implements OnInit {
  constructor() {}
  transactionTypes = TransactionType;
  screenSize = ScreenType;
  ngOnInit() {}
}
