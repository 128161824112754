import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-rewards-layout',
  templateUrl: './rewards-layout.component.html',
  styleUrls: ['./rewards-layout.component.scss'],
})
export class RewardsLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
