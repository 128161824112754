<ng-container *ifScreenSize="screenSize.Desktop">
  <div class="banner" role="banner">
    <h1>{{ 'account.activity.page.title' | translate }}</h1>
  </div>
</ng-container>

<ng-container *ifScreenSize="screenSize.Mobile">
  <div class="container mt-3">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>

<fgb-account-nav></fgb-account-nav>

<div class="container">
  <div class="points-history">
    <fgb-transaction-history [transactionTypes]="[transactionTypes.loyalty]" [accountView]="true"></fgb-transaction-history>
  </div>
</div>
