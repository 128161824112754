import { Component, OnInit } from '@angular/core';
import { MemberQuery } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'fgb-account-details-page',
  templateUrl: './account-details-page.component.html',
  styleUrls: ['./account-details-page.component.scss'],
})
export class AccountDetailsPageComponent implements OnInit {
  name$: Observable<string>;
  screenSize = ScreenType;
  constructor(private memberQuery: MemberQuery) {}

  ngOnInit() {
    this.name$ = this.memberQuery.selectMemberDetails().pipe(
      map((m) => {
        if (m) {
          return `${m.FirstName} ${m.Surname}`;
        }
        return '';
      })
    );
  }
}
