import { Component, OnInit } from '@angular/core';
import { MixedLoyaltyQuery, RewardsWalletService, RewardsWalletItem, RewardsWalletQuery } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-wallet-page',
  templateUrl: './wallet-page.component.html',
  styleUrls: ['./wallet-page.component.scss'],
})
export class WalletPageComponent implements OnInit {
  walletItemsUnclaimed$: Observable<RewardsWalletItem[]>;
  screenSize = ScreenType;

  constructor(
    private mixedLoyaltyQuery: MixedLoyaltyQuery,
    private rewardWalletItemsService: RewardsWalletService,
    private rewardWalletQuery: RewardsWalletQuery
  ) {}

  async ngOnInit() {
    this.rewardWalletItemsService.fetchWalletItems().toPromise();
    this.walletItemsUnclaimed$ = this.rewardWalletQuery.selectRewardWalletItemsByStatus(false);
    await this.mixedLoyaltyQuery.walletFetch();
  }
}
