<div class="full-page w-100 d-lg-flex d-block px-0 h-100">
  <!--Left Side-->
  <div class="left-side-login">
    <img
      class="shared-login-banner d-lg-block d-none h-100 bg-primary"
      draggable="false"
      src="/assets/images/login-registration/login-banner-v2.jpg"
    />
  </div>
  <div class="bg-primary d-flex w-100 d-lg-none m-auto mobile-header"></div>
  <!--Right Side-->
  <div class="p-4 right-side-login">
    <div class="login-container text-center">
      <div class="text-primary icon material-icons">engineering</div>
      <h5 class="text-primary my-1 text-capitalize" [innerHTML]="'maintenance.page.title' | translate | markdownTranslate"></h5>
      <div class="font-size-14" [innerHTML]="'maintenance.page.details' | translate | markdownTranslate"></div>
    </div>
  </div>
</div>
