<div class="card my-lg-4 my-3 bg-tertiary">
  <div class="card-content px-3">
    <div class="row">
      <div class="col-lg-5">
        <h5 class="mt-1 mt-0 mb-lg-0 mb-3 font-weight-bold text-white text-capitalize">
          {{ 'voucher.title' | translate }}
        </h5>
      </div>
      <div class="col-lg-4">
        <span class="input-group">
          <input
            type="text"
            [(ngModel)]="voucherCode"
            placeholder="INSERT CODE HERE"
            class="form-control text-center border-0 px-5 px-lg-0"
            (keyup.enter)="redeemVoucher()"
          />
          <div class="input-group-append">
            <button
              class="btn btn-primary icon text-white font-weight-bold material-icons py-0"
              type="button"
              (click)="redeemVoucher()"
              [disabled]="disableButton"
            >
              arrow_forward_ios
            </button>
          </div>
        </span>
        <div class="text-center">
          <fgbcl-errors></fgbcl-errors>
        </div>

        <div class="result text-center" *ngIf="redemptionResult">
          <div
            class="font-size-10 mt-1 text-success"
            [ngClass]="redemptionResult === sucessfulRedemption ? 'text-success' : 'text-white'"
          >
            {{ redemptionResult }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
