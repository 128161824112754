import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountDetailsComponent } from './components/account-details/account-details.component';
import { AuthCallbackComponent } from './components/auth/auth-callback/auth-callback.component';
import { CardListItemComponent } from './components/cards/card-list-item/card-list-item.component';
import { MemberCardComponent } from './components/member-card/member-card.component';
import { SingleSignOnComponent } from './components/single-sign-on/single-sign-on.component';
import { UserLoginComponent } from './components/user-login/user-login.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BarcodeComponent } from './components/barcode/barcode.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { MlbamUserLoginComponent } from './components/mlbam-user-login/mlbam-user-login.component';
import { TransactionListComponent } from './components/transaction-list/transaction-list.component';
import { TransactionHistoryComponent } from './components/transaction-history/transaction-history.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { CardListComponent } from './components/cards/card-list/card-list.component';
import { CardSelectComponent } from './components/cards/card-select/card-select.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TmUserLoginComponent } from './components/tm-user-login/tm-user-login.component';
import { EditAccountDetailsComponent } from './components/edit-account-details/edit-account-details.component';
import { SportsAllianceUserLoginComponent } from './components/sports-alliance-user-login/sports-alliance-user-login.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ErrorsModule } from '@fgb/portal-component-library/src/lib/shared/errors';
import { DateRangePickerModule } from '@fgb/portal-component-library/src/lib/shared/date-range-picker';
import { CashBackToggleModule } from '@fgb/portal-component-library/src/lib/ecash/cash-back-toggle';
import { LoadingModule } from '@fgb/portal-component-library/src/lib/shared/loading';
import { SeatGeekUserLoginComponent } from './components/seatgeek-user-login/seatgeek-user-login.component';
import { MobilePassModule } from '@fgb/portal-component-library/src/lib/passes/mobile-pass';
import { InvalidTokenModalComponent } from './components/invalid-token-modal/invalid-token-modal.component';
import { PointsSummaryComponent } from './components/points-summary/points-summary.component';
import { PointsSummaryBarComponent } from './components/points-summary-bar/points-summary-bar.component';

@NgModule({
  declarations: [
    AccountDetailsComponent,
    AuthCallbackComponent,
    CardListItemComponent,
    MemberCardComponent,
    SingleSignOnComponent,
    UserLoginComponent,
    BarcodeComponent,
    MlbamUserLoginComponent,
    TransactionListComponent,
    TransactionHistoryComponent,
    CardListComponent,
    CardSelectComponent,
    TmUserLoginComponent,
    EditAccountDetailsComponent,
    SportsAllianceUserLoginComponent,
    SeatGeekUserLoginComponent,
    InvalidTokenModalComponent,
    PointsSummaryComponent,
    PointsSummaryBarComponent,
  ],
  exports: [
    AccountDetailsComponent,
    AuthCallbackComponent,
    CardListItemComponent,
    MemberCardComponent,
    SingleSignOnComponent,
    UserLoginComponent,
    BarcodeComponent,
    MlbamUserLoginComponent,
    TransactionHistoryComponent,
    CardListComponent,
    CardSelectComponent,
    TmUserLoginComponent,
    EditAccountDetailsComponent,
    SportsAllianceUserLoginComponent,
    SeatGeekUserLoginComponent,
    PointsSummaryComponent,
    PointsSummaryBarComponent,
  ],
  imports: [
    NgbModule,
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    QRCodeModule,
    RouterModule,
    InlineSVGModule,
    CarouselModule,
    DateRangePickerModule,
    ErrorsModule,
    CashBackToggleModule,
    LoadingModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: function HttpLoaderFactory(http: HttpClient) {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
    MobilePassModule,
  ],
})
export class UserModule {}
