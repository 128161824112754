<div *ngIf="lottoItem; else noPurchase">
  <div class="d-block d-lg-flex border-0 rounded overflow-hidden mt-lg-4 mt-1 justify-content-center align-items-center">
    <div class="item-details d-flex flex-column align-items-center flex-grow-1 flex-shrink-1 p-3">
      <div class="d-lg-flex d-block">
        <div>
          <div class="text-success mb-0 fw-bold font-size-18 text-start mb-1">
            {{ 'lotto.entry.successful' | translate }}
          </div>
          <p class="mb-0 text-success-page font-size-14 pe-lg-4 lotto-success-description">
            <ng-container>{{ 'raffles.purchase.confirm' | translate }}</ng-container>
          </p>
          <div class="d-flex border-0 mt-4">
            <a class="btn btn-primary confirm-btn fw-bold text-white" [routerLink]="['/rewards/wallet']">{{
              'rewards.back.to.wallet' | translate
            }}</a>
          </div>
        </div>
        <div class="rounded payment-details bg-tertiary">
          <div class="d-lg-flex d-block pb-lg-2">
            <div class="mt-lg-3 me-4">
              <img
                class="purchase-success-image rounded"
                src="{{ lottoItem.ImageName | contentImage : '6' : 'lotto' }}"
                alt="{{ lottoItem.ImageName }}"
                [useDefault]="true"
                [loyaltyTyps]="'lotto'"
                draggable="false"
              />
            </div>
            <div class="mt-3">
              <!--Name-->
              <div class="font-size-12 mb-3 fw-bold font-size-14 text-success-page">{{ lottoItem.Title }}</div>
              <!--Quantity-->
              <div class="d-flex align-items-center mt-2 text-success-page">
                <span class="font-size-12 lotto-details-spacing">{{ 'lotto.entries' | translate }}</span>
                <span class="fw-bold font-size-14 ps-2">{{ quantity | number }}</span>
              </div>
              <!--Price-->
              <div class="d-flex align-items-center mt-2 text-success-page">
                <span class="font-size-12 lotto-details-spacing">{{ 'lotto.points.used' | translate }}</span>
                <span class="fw-bold font-size-14 ps-2"
                  >{{ totalPrice | number }} {{ 'lottos.points.value.pts' | translate }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #noPurchase>
  <div class="text-center m-5">No raffles found...</div>
</ng-template>
