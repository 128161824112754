import { Component, OnInit } from '@angular/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';

@Component({
  selector: 'fgb-rewards-page',
  templateUrl: './rewards-page.component.html',
  styleUrls: ['./rewards-page.component.scss'],
})
export class RewardsPageComponent implements OnInit {
  constructor() {}
  screenSize = ScreenType;
  filterTypesList: { label: string; value: string }[] = [{ label: 'reward.filter_modal.all', value: 'all' }];
  selectedValues: string[] = ['all'];
  showOffCanvas: boolean = false;

  ngOnInit() {}

  handleFilterTypesListEmitter(filterTypes: { label: string; value: string }[]) {
    this.filterTypesList = [...filterTypes];
  }

  handleSelectedValuesEmitter(selectedValues: string[]) {
    this.selectedValues = [...selectedValues];
  }

  handleShowOffCanvasEmitter(showOffCanvas: boolean) {
    this.showOffCanvas = showOffCanvas;
  }
}
